// Node Modul, dass in gatsby-browser inkludiert wird um die Browse-Happy-Bar anzuzeigen

/**
 * Testet, ob der Browser das angegebene `prop` unterstützt
 * @param {string} prop
 * @returns {boolean}
 *
 * @example if(browserSupports('borderImage')) ...
 */
const browserSupports = prop => {
    const div = document.createElement('div');
    const vendors = 'Khtml Ms O Moz Webkit'.split(' ');
    let len = vendors.length;
    let property = prop;

    if (prop in div.style) return true;

    property = property.replace(/^[a-z]/, val => val.toUpperCase());

    while (len > 0) {
        len -= 1;
        if (vendors[len] + property in div.style) {
            return true;
        }
    }
    return false;
};

/**
 * Testet, ob der Browser zu alt ist und zeigt ggf. die BrowseHappyBar an
 * @returns {null}
 *
 * @example browseHappy()
 */
const browseHappy = () => {
    if (
        typeof window === 'undefined' ||
        (browserSupports('borderImage') && !('msMaxTouchPoints' in navigator))
    ) {
        return;
    }

    const browseHappyBar = document.createElement('div');
    browseHappyBar.setAttribute(
        'style',
        `
        background-color: #cc4b37;
        box-sizing: border-box;
        color: #ffffff;
        display: block;
        font-family: Arial, sans-serif;
        font-size: 15px;
        font-weight: bold;
        padding: 15px;
        position: relative;
        text-align: center;
        width: 100%;
        z-index: 10;
        `
    );

    browseHappyBar.innerHTML = `Du verwendest einen stark veralteten Browser. Bitte <a href="https://outdatedbrowser.com/de" target="_blank" style="color: #ffffff; text-decoration: underline;" rel="noopener noreferrer">aktualisiere deinen Browser</a> für eine optimale Nutzererfahrung.`;
    document.body.insertAdjacentElement('afterbegin', browseHappyBar);
};

module.exports = { browseHappy };
