const BrowseHappy = require('./src/helpers/browse-happy');

exports.onRouteUpdate = () => {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('off-canvas-active');
};

// Polyfills für IE 10 (@see https://github.com/gatsbyjs/gatsby/issues/2177#issuecomment-382280801)
// React braucht diese Polyfills, und die werden nicht automatisch von Babel gepolyfilled, da es in
// node_modules hängt.
require('core-js/fn/set');
require('core-js/fn/map');
require('core-js/fn/array/from');
require('core-js/fn/object/entries');
require('core-js/fn/string/starts-with');

// IO Polyfill für Safari und iOS
// https://caniuse.com/#feat=intersectionobserver
require('intersection-observer');

exports.onClientEntry = () => {
    BrowseHappy.browseHappy();
};
